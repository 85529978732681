<script>
import CommonLayout from "@/views/CommonLayout.vue";

export default {
  name: "TermsConditions",
  components: {
    CommonLayout
  },
  beforeMount() {
    window.scrollTo({ top: 0, left: 0 });
  }
};
</script>

<template>
  <CommonLayout>
    <div class="content">
      <div class="terms-content">
        <div class="heading-container animate__animated animate__fadeInDown">
          <h1 class="page-title">Terms & Conditions</h1>
        </div>
        <div class="column">
          <ol class="ordered-list animate__animated animate__fadeIn animate__slow">
            <li>
              <h class="h6">Your Agreement</h><br /><br />
              This website www.explorethelocal.com ('ETL website') is operated by Explore the Local
              (‘ETL’). Please read these terms of use (“Terms of Use”) before using the ETL website
              and the services offered by ETL, its affiliated companies or third-party operators
              (‘ETL Operators’) through the ETL website (the “Services”). “You” and “your” when used
              in this Terms of Use includes (1) any person who accesses the ETL website and (2)
              persons for whom you make a purchase of the Services.
            </li>
            <br />

            <li>
              <h class="h6">Change of Terms of Use</h><br /><br />

              ETL reserves the right, at its sole discretion, to change or modify any part of this
              Terms of Use at any time without prior notice. You should visit this page periodically
              to review the current Terms of Use to which you are bound. If ETL changes or modifies
              this Terms of Use, ETL will post the changes to or modifications of this Terms of Use
              on this page and will indicate at the bottom of this page the date on which this Terms
              of Use was last revised.<br /><br />

              Your continued use of the ETL website after any such changes or modifications
              constitutes your acceptance of the revised Terms of Use. If you do not agree to abide
              by the revised Terms of Use, do not use or access or continue to use or access the ETL
              website and/or the Services. It is your responsibility to regularly check the ETL
              website to ascertain if there are any changes to this Terms of Use and to review such
              changes.<br /><br />

              In addition, when using the Services, you shall be subject to any additional terms
              applicable to such. Services that may be posted on the page relating to such Services
              from time to time and the privacy policy adopted by ETL from time to time (“the
              Privacy Policy”). All such terms are hereby expressly incorporated by reference in
              this Terms of Use.
            </li>
            <br />

            <li>
              <h class="h6">Access and Use of the Services</h><br /><br />

              <ol class="ordered-list">
                <li>
                  Ownership of Content<br /><br />

                  The ETL website, the domain name (www.explorethelocal.com), subdomains, features,
                  contents and application services (including without limitation to any mobile
                  application services) offered periodically by ETL in connection therewith are
                  owned and operated by ETL.<br /><br />
                </li>

                <li>
                  Provision and Accessibility of Services<br /><br />

                  Subject to this Terms of Use, ETL may either offer to provide the Services by
                  itself or on behalf of the ETL Operators, as described in further detail on the
                  ETL website. The Services that have been selected by you on the ETL Platform are
                  solely for your own use, and not for the use or benefit of any third party. The
                  term "Services" includes but is not limited to the use of the ETL website, any
                  Services offered by ETL by itself or on behalf of the Operators on the ETL
                  website. ETL may change, suspend or discontinue any Services at any time,
                  including the availability of any feature, database or content. ETL may also
                  impose limits or conditions on certain Services or restrict your access to any
                  part or all of the Services without notice or liability.<br /><br />

                  ETL does not guarantee that the Services will always be available or
                  uninterrupted. ETL will not be liable to you if for any reason the Services are
                  unavailable at any time or for any period. You are responsible for making all
                  arrangements necessary for you to have access to the Services. You are also
                  responsible for ensuring that all persons who access the Services through Internet
                  connection are aware of this Terms of Use and other applicable terms and
                  conditions for the Services, and that they comply with them.<br /><br />

                  If you link to the ETL website, ETL may revoke your rights to link at any time, at
                  ETL’s sole discretion. ETL reserves the right to require prior written consent
                  before linking to the ETL website.
                </li>
              </ol>
            </li>
            <br />

            <li>
              <h class="h6">ETL website and Content</h><br /><br />

              <ol class="ordered-list">
                <li>
                  Use of the Content<br /><br />

                  All materials displayed or performed on the ETL website including but not limited
                  to text, data, graphics, articles, photographs, images, illustrations, video,
                  audio and other materials (“Content”) are protected by copyright and/or other
                  intellectual property rights. This ETL website and the Content are intended solely
                  for your personal and non-commercial use of the Services and may only be used in
                  accordance with this Terms of Use.<br /><br />

                  If ETL agrees to grant you access to the ETL website and/or the Content, such
                  access shall be non-exclusive, non-transferable and limited license to access the
                  ETL website in accordance with this Terms and Use. ETL may, at its absolute
                  discretion and at any time, without prior notice to you, amend or remove or alter
                  the presentation, substance or functionality of any part or all of the Content
                  from the ETL website.<br /><br />

                  You shall abide by all copyright notices, trademark rules, information, and
                  restrictions contained in the ETL website and the Content accessed through the ETL
                  website, and shall not use, copy, reproduce, modify, translate, publish,
                  broadcast, transmit, distribute, perform, upload, display, license, sell or
                  otherwise exploit for any purposes whatsoever the ETL website or the Content or
                  third party submissions or other proprietary rights not owned by you without the
                  express prior written consent of the respective owners, or in any way that
                  violates any third party rights.<br /><br />
                </li>

                <li>
                  ETL’s Liability for the ETL website and Content<br /><br />

                  ETL cannot guarantee the identity of any other users with whom you may interact
                  with in the course of using the ETL website. ETL cannot guarantee the authenticity
                  and accuracy of any content, materials or information which other users or the
                  Operators may provide. All Content accessed by you using the ETL website is at
                  your own risk and you will be solely responsible for any damage or loss to any
                  party resulting therefrom.<br /><br />

                  Under no circumstances will ETL be liable in any way for any Content, including
                  but not limited to any errors or omissions in any Content, or any loss or damage
                  of any kind incurred in connection with the use of or exposure to any Content
                  posted, emailed, accessed, transmitted, or otherwise made available via the ETL
                  website.<br /><br />
                </li>

                <li>
                  Sort Order<br /><br />

                  We provide sorting and filter settings for you to adapt the search results to your
                  preferences using criteria such as review ratings, availability, price
                  recommendations, popularity or other criteria. We continually optimize the ETL
                  website to provide you the best experience and may test different default sort
                  order algorithms from time to time<br /><br />
                </li>
              </ol>
            </li>
            <br />

            <li>
              <h class="h6">Intellectual Property Rights</h><br /><br />

              <ol class="ordered-list">
                <li>
                  Intellectual Property<br /><br />

                  All intellectual property rights subsisting in respect of the ETL website belong
                  to ETL or have been licensed to ETL for use on the ETL website. This ETL website,
                  the Services and the Content are protected by copyright and other intellectual
                  property rights as collective works and/or compilations, pursuant to applicable
                  copyright laws, international conventions, and other intellectual property laws.
                  You undertake that: (a) You shall not modify, publish, transmit, participate in
                  the transfer or sale of, reproduce, create derivative works based on, distribute,
                  perform, display, or in any way exploit, any part of the ETL website and the
                  Content, software, materials, or the Services in whole or in part; (b) You shall
                  only download or copy the Content (and other items displayed on the ETL website or
                  related to the Services) for personal and non-commercial use only, provided that
                  you maintain all copyright and other notices contained in such Content; and (c)
                  You shall not store any significant portion of any Content in any form. Copying or
                  storing of any Content other than personal and non-commercial use is expressly
                  prohibited without prior written permission from ETL or from the copyright holder
                  identified in such Contents copyright notice.<br /><br />
                </li>
              </ol>
            </li>
            <br />

            <li>
              <h class="h6">User Submissions</h><br /><br />

              <ol class="ordered-list">
                <li>
                  Uploading of Information<br /><br />

                  In the course of accessing the ETL website or using the Services, you may provide
                  information which may be used by ETL and/or the Operators in connection with the
                  Services and which may be visible to other users of the ETL website. You
                  understand that by posting information or content on the ETL website or otherwise
                  providing content, materials or information to ETL and/or the ETL Operators in
                  connection with the Services (“User Submissions”): (a) You hereby grant to ETL and
                  the ETL Operators a non-exclusive, worldwide, royalty free, perpetual,
                  irrevocable, sub-licensable and transferable right to use and fully exploit such
                  User Submissions, including all related intellectual property rights subsisted
                  thereon, in connection with providing the Services and operating the ETL website
                  and ETL’s business, including but not limited to the promotion and redistribution
                  of part or all of the Services and derivative works thereof in any media formats
                  and through any media channels; (b) You agree and authorize ETL to use your
                  personal data in accordance with the Privacy Policy in effect from time to time;
                  (c) You hereby grant each user of the ETL website a non-exclusive license to
                  access your User Submissions through the ETL website, and to use, modify,
                  reproduce, distribute, prepare derivative works of, display and perform such User
                  Submissions as permitted through the functionality of the ETL website and under
                  this Terms of Use; (d) You acknowledge and agree that ETL retains the right to
                  reformat, modify, create derivative works of, excerpt, and translate any User
                  Submissions submitted by you. For clarity, the foregoing license grant to ETL does
                  not affect your ownership of or right to grant additional non-exclusive licenses
                  to the material in the User Submissions, unless otherwise agreed in writing; (e)
                  You hereby represent and warrant that any content in your User Submission
                  (including but not limited to text, graphics and photographs) do not infringe any
                  applicable laws, regulations or any third party rights; and (f) That all the User
                  Submissions publicly posted or privately transmitted through the ETL website is
                  the sole responsibility of you and that ETL will not be liable for any errors or
                  omissions in any content.<br /><br />
                </li>
              </ol>
            </li>
            <br />

            <li>
              <h class="h6">Users Representations, Warranties and Undertakings</h><br /><br />

              <ol class="ordered-list">
                <li>
                  Use of the ETL website and the Services<br /><br />

                  You represent, warrant and undertake to ETL that you will not provide any User
                  Submissions or otherwise use the ETL website or the Services in a manner that: (a)
                  Infringes or violates the intellectual property rights or proprietary rights,
                  rights of publicity or privacy, or other rights of any third party; or (b)
                  Violates any law, statute, ordinance or regulation; or (c) Is harmful, fraudulent,
                  deceptive, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene,
                  libelous, or otherwise objectionable; or (d) Involves commercial activities and/or
                  sales without ETL’s prior written consent such as contests, sweepstakes, barter,
                  advertising, or pyramid schemes; or (e) Constitutes libel, impersonates any person
                  or entity, including but not limited to any employee or representative of ETL; or
                  (f) Contains a virus, trojan horse, worm, time bomb, or other harmful computer
                  code, file, or program.<br /><br />
                </li>

                <li>
                  Removal of User Submissions<br /><br />

                  ETL reserves the right to remove any User Submissions from this ETL website at any
                  time, for any reason including but not limited to, receipt of claims or
                  allegations from third parties or authorities relating to such User Submission or
                  if ETL is concerned that you may have breached any of the preceding
                  representations, warranties or undertakings, or for no reason at all.<br /><br />
                </li>

                <li>
                  Responsibility for User Submissions<br /><br />

                  You remain solely responsible for all User Submissions that you upload, post,
                  email, transmit, or otherwise disseminate using, or in connection with, the ETL
                  website.<br /><br />

                  You acknowledge and agree that you shall be solely responsible for your own User
                  Submissions and the consequences of posting or publishing all of your User
                  Submissions on the ETL website. You represent, warrant and undertake to ETL that:
                  (a) You own or have the necessary rights, licenses, consents, releases and/or
                  permissions to use and authorize ETL to use all copyright, trademark or other
                  proprietary or intellectual property rights in and to any User Submission to
                  enable inclusion and use thereof as contemplated by the ETL website and this Terms
                  of Use; and (b) Neither the User Submissions nor your posting, uploading,
                  publication, submission or transmittal of the User Submission or ETL’s use of the
                  User Submissions, or any portion thereof, on or through the ETL website and/or the
                  Services will infringe, misappropriate or violate any third party’s patent,
                  copyright, trademark, trade secret, moral rights or other proprietary or
                  intellectual property rights, or rights of publicity or privacy, or result in the
                  violation of any applicable law, rule or regulation.<br /><br />

                  You are responsible for all of your activity in connection with using the ETL
                  website and/or the Services. You further represent, warrant and undertake to ETL
                  that you shall not: (a) Conduct any fraudulent, abusive, or otherwise illegal
                  activity which may be grounds for termination of your right to access or use the
                  ETL website and/or the Services; or (b) sell or resell any products, services or
                  reservation obtained from or via the ETL website; (c) use the ETL website for
                  commercial or competitive activity or purposes, or for the purpose of making
                  speculative, false or fraudulent bookings or any reservations in anticipation of
                  demand; (d) Post or transmit, or cause to be posted or transmitted, any
                  communication or solicitation designed or intended to obtain password, account, or
                  private information from any other user of the ETL website; or (e) Violate the
                  security of any computer network, crack passwords or security encryption codes,
                  transfer or store illegal material (including material that may be considered
                  threatening or obscene), or engage in any kind of illegal activity that is
                  expressly prohibited; or (f) Run maillist, listserv, or any other form of
                  auto-responder, or "spam" on the ETL website, or any processes that run or are
                  activated while you are not logged on to the ETL website, or that otherwise
                  interfere with the proper working of or place an unreasonable load on the ETL
                  website’s infrastructure; or (g) Use manual or automated software, devices, or
                  other processes to "crawl," "scrape," or "spider" any page of the ETL website; or
                  (h) Decompile, reverse engineer, or otherwise attempt to obtain the source code of
                  ETL website.<br /><br />

                  You will be responsible for withholding, filing, and reporting all taxes, duties
                  and other governmental assessments associated with your activity in connection
                  with using the ETL website and/or the Services.<br /><br />
                </li>
              </ol>
            </li>
            <br />

            <li>
              <h class="h6">Registration and Security</h><br /><br />

              <ol class="ordered-list">
                <li>
                  Provision of Personal Information<br /><br />

                  As a condition to using some aspects of the Services, you may be required to
                  register with ETL and select a password and user name (“ETL User ID”). If you are
                  accessing the Services through a Third Party Website or service, ETL may require
                  that your ETL User ID be the same as your user name for such Third Party Website
                  or service<br /><br />

                  You shall provide ETL with accurate, complete, and updated registration
                  information. Failure to do so shall constitute a breach of this Terms of Use,
                  which may result in immediate termination of your ETL Account.<br /><br />

                  You represent that you shall not: (a) Select or use as a ETL User ID a name of
                  another person with the intent to impersonate that person; or (b) Use as a ETL
                  User ID a name subject to any rights of a person other than you without
                  appropriate authorization.<br /><br />

                  ETL reserves the right to refuse registration of or to cancel a ETL Account at its
                  sole discretion. You shall be responsible for maintaining the confidentiality of
                  your password.<br /><br />
                </li>
              </ol>
            </li>
            <br />

            <li>
              <h class="h6"
                >Reviews - Further correspondence – Promotions - Rights to User Content</h
              ><br /><br />

              By completing a booking, you agree to receive confirmation messages (in the form of
              emails and/or app notifications), as well as an invitation email(s) or app
              notification(s) for you to complete our guest review form which we will send to you
              after you finish an activity. Leaving a review is optional. For clarity, the
              confirmation and guest review emails are transactional and are not part of the company
              updates or marketing mails, from which you can unsubscribe. The completed guest review
              may be uploaded onto the relevant activity page on the ETL website within 72 hours of
              the submission for the sole purpose of informing (future) customers of your opinion of
              the service (level) and quality of the Activity. Upon submitting a review, your
              account may be awarded ETL credits, which may be used towards your next booking
              subject to terms and conditions. Each account may only submit one review per activity
              booked once or multiple times within the same calendar month. Fraud and abuse will
              result in the forfeiture of ETL credits. further reserves the right to deduct any
              credits directly from your ETL account without prior notice.<br /><br />

              By posting a review, you grant ETL the full, perpetual, free, transferable and
              irrevocable rights to all submitted user content. ETL reserves the right to translate,
              edit, adjust, refuse or remove reviews at its sole discretion.<br /><br />

              You confirm you will comply with these Guest Review Guidelines. In addition, you
              represent and warrant that:<br /><br />

              <p class="bullet-point">
                <span>&#8226; </span>You own and control all of the rights to the user content that
                you post or otherwise distribute, or you otherwise have the lawful right to post and
                distribute such user content to or through the ETL website;
              </p>

              <p class="bullet-point">
                <span>&#8226; </span>Such content is accurate and not misleading; and
              </p>

              <p class="bullet-point">
                <span>&#8226; </span>
                Use and posting or other transmission of such content does not violate the Terms of
                Use or any applicable laws and regulations and will not violate any rights of or
                cause injury to any person or entity.
              </p>
              <br />

              Reviews must not contain obscenities, profanity, inappropriate content, hate speech
              and offensive content, promotion of illegal conduct, other people’s personal
              information such as names, phone numbers or email addresses, and irrelevant content
              such as promotional, invite and reward information. Moreover, reviews may not defame,
              abuse, harass, or violate the legal rights of others.<br /><br />

              You further grant ETL the right to pursue at law any person or entity that violates
              your or ETL's rights in the content by a breach of the Terms of Use. You agree you
              will be solely responsible for any user content you provide or submit.<br /><br />

              Content submitted by users will be considered non-confidential and ETL is under no
              obligation to treat such content as proprietary information. Without limiting the
              foregoing, ETL reserves the right to use the content as it deems appropriate,
              including, without limitation, deleting, editing, modifying, rejecting, or refusing to
              post it. ETL is under no obligation to offer you any payment for content that you
              submit or the opportunity to edit, delete or otherwise modify content once it has been
              submitted to ETL. ETL shall have no duty to attribute authorship of content to you,
              and shall not be obligated to enforce any form of attribution by third parties.
            </li>
            <br />

            <li>
              <h class="h6">Booking Confirmation, Tickets, Vouchers, Fees and Payment</h
              ><br /><br />

              <ol class="ordered-list">
                <li>
                  Booking Confirmation<br /><br />

                  Certain Services are stated to be subject to instant confirmation. Other than
                  these Services, any required time for confirmation as stated on the ETL website is
                  solely for reference only. Actual time required for confirmation may vary.
                </li>
                <br />

                <li>
                  Purchase and Use of Vouchers<br /><br />

                  Through the ETL website, you may purchase vouchers from ETL for the Services
                  (“Vouchers”) offered by the Operators in the various destinations. Subject to the
                  policy of the relevant Operator, you will receive an email confirmation of your
                  purchase that will contain a Voucher confirmation number (“Confirmation Number”)
                  and a printable version of your Voucher.<br /><br />

                  In order to use your Voucher, you must appear in person at the meeting point
                  designated by the relevant Operator on time, and present such documents and/or
                  information as may be required by the Operator, that may include your Confirmation
                  Number and/or your printed Voucher. If you fail to appear on time or to provide
                  the required documents or information, no refunds will be granted.<br /><br />

                  An Operator may also require you to provide an official identification document
                  bearing your photo in order to use your Voucher. Neither ETL nor the Operator is
                  responsible for lost, destroyed or stolen Vouchers or Confirmation Numbers.
                  Vouchers will be void if the relevant Services to be provided are prohibited by
                  law. If you attempt to use a Voucher in an unlawful manner (e.g., you attempt to
                  use a Voucher for wine tasting when you are under the legal age to do so), the
                  respective Operator may refuse to accept your Voucher, and no refunds will be
                  granted.<br /><br />
                </li>

                <li>
                  Terms of the Vouchers<br /><br />

                  The Terms of Use for each Voucher may vary amongst Operators and any restrictions
                  that apply to the use of such Voucher, including but not limited to a minimum age
                  requirement, will be conveyed to you at the time of purchase on the ETL
                  website.<br /><br />

                  Vouchers are admission tickets to one-time events ('Events'): the date(s) on which
                  a Voucher can be used will be stated on the Voucher. If you do not use your
                  Vouchers on or by the date(s) noted on such Vouchers, except as expressly set
                  forth therein, no refunds will be granted.<br /><br />
                </li>

                <li>
                  Cancellation of Vouchers<br /><br />

                  You may cancel your Voucher by contacting ETL customer service within the
                  cancellation period, as stated at the time of purchase on the ETL website.
                  Cancellation windows vary on a case by case basis. A Voucher cancelled with the
                  required notice will be refunded in full to the credit card you used to purchase
                  such Voucher.<br /><br />

                  The ETL Operator, not ETL, is the offeror of the Services for the Events, to which
                  the Vouchers correspond to, and is solely responsible for accepting or rejecting
                  any Voucher you purchase, as related to all such Services.<br /><br />

                  Please directly consult with the Operator if you have any enquiries or complaints
                  in respect of the Service you received in connection with your Voucher. Except as
                  expressly set forth herein, all fees paid for Vouchers are non-refundable. Prices
                  quoted for Vouchers are in the currency stated on the ETL website at the time
                  prior to purchase.<br /><br />

                  If an Event which you have purchased a Voucher for is cancelled by the ETL
                  Operator, ETL will notify you as soon as reasonably practicable, and will process
                  a full refund to the credit card you used to purchase such Voucher.<br /><br />
                </li>

                <li>
                  Required Assistance<br /><br />

                  If you attempt to use a Voucher in accordance with this Terms of Use and the
                  additional terms and conditions applicable to such Vouchers and you are unable to
                  do so (due to the fault of the ETL Operator or otherwise), please contact ETL at
                  info@explorethelocal.com, and ETL will try to liaise with the ETL Operator for an
                  appropriate remedy.<br /><br />
                </li>

                <li>
                  Additional Charges<br /><br />

                  ETL reserves the right to require payment of fees or charges for any Services
                  offered by ETL. You shall pay all applicable fees or charges, as described on the
                  ETL website in connection with such Services selected by you.<br /><br />
                </li>

                <li>
                  Modifications to Charges<br /><br />

                  ETL reserves the right to change its price list for fees or charges at any time,
                  upon notice to you, which may be sent to you by email or posted on the ETL
                  website. Your use, or continued use, of the Services offered by ETL following such
                  notification constitutes your acceptance of any new or revised fees or charges.<br /><br />
                </li>

                <li>
                  ETL’s Rights and Obligations<br /><br />

                  ETL reserves the right to deny and cancel bookings or purchases of any Services
                  that are deemed in violation of this policy. Such a determination is at ETL’s sole
                  discretion.<br /><br />

                  ETL intends to offer or procure the ETL Operators to offer the Services to you at
                  the best price available on the market. You acknowledge and agree that all taxes
                  and additional fees for particular Services that may be payable for using the
                  Services are expressly excluded in determining the best price.<br /><br />

                  Whilst ETL Operators are required to provide ETL with accurate and updated prices
                  of the Services on the ETL website, ETL cannot guarantee that all prices for the
                  Services provided by the ETL Operators are accurate and updated at all times.<br /><br />
                </li>

                <li>
                  The above terms and conditions & return policies are appliable to all ETL users
                  worldwide.<br /><br />
                </li>
              </ol>
            </li>

            <li>
              <h class="h6">Discounts</h><br /><br />

              <ol class="ordered-list">
                <li>
                  ETL Credits<br /><br />

                  ETL Credits are points that are awarded to you and will be accumulated in your ETL
                  Account until expiry. Points are awarded on the actual participated and unrefunded
                  activities purchased from ETL’s platforms, based on the award program at the time
                  of purchase. Except as otherwise stated, every ten (10) ETL Credits may be used to
                  offset one hundred Sri Lankan Rupees of the total check out price. Conversion into
                  any other currencies will be calculated based on the exchange rate ETL uses on the
                  date of your purchase offset.<br /><br />

                  ETL Credits may be earned through the following means and the amount of ETL Credit
                  received may vary with each purchase and is at the sole discretion of ETL: (a)
                  rebates from previous purchases (b) earning through any means authorized by ETL at
                  its sole discretion (c) submission of activity review after successfully
                  completing any eligible ETL activities you purchased.<br /><br />

                  You will only receive ETL Credits for the first ten activities you have reviewed
                  in any calendar month. If you have completed the same activity more than once in
                  the same month, you will only receive ETL Credits for the first review you have
                  submitted for that activity.<br /><br />

                  All ETL Credits earned any time in the first calendar year will expire on the last
                  day of the next calendar year. For example, if you are awarded an ETL Credit on 1
                  October 2020, that ETL Credit will expire and therefore be deducted from your
                  account on 31 December 2021.<br /><br />

                  Booking and delivery fees and charges are not eligible for earning ETL Credits,
                  nor can ETL Credits be used to redeem or deduct booking and delivery fees and
                  charges.<br /><br />
                </li>
                <br />

                <li>
                  ETL Coupons<br /><br />

                  ETL Coupons are coupons with a one-time use and will not be returned if used that
                  will be sent to your designated email address or applied directly to your ETL
                  Account, which may be used in exchange for discounts of future bookings on the ETL
                  Booking Platform. For the avoidance of doubt, once you have used the ETL Coupons
                  on the ETL website, such ETL Coupons will not be returned or refunded under any
                  circumstances.<br /><br />

                  ETL Credits may be attained through the following means: (a) You may receive ETL
                  Coupons through the ETL Referral Program, as further elaborated on in Clause 11;
                  or (b) You may receive ETL Coupons by other authorized means as determined at
                  ETL’s sole discretion.<br /><br />
                </li>

                <li>
                  Abuse of Discounts<br /><br />

                  In the event where it has come to ETL’s attention that the ETL Credits or ETL
                  Coupons were earned in a fraudulent manner, in a manner that violates this Terms
                  of Use or in a manner otherwise not intended by ETL, ETL reserves the right to the
                  following: (a) Termination of your ETL Account with immediate effect; or (b)
                  Cancelation of all ETL Credits or ETL Coupons as previously accrued; or (c)
                  Refusal of the provision of the Services to you; or (d) Any other measures as
                  deemed appropriate by ETL at its sole discretion.<br /><br />
                </li>
              </ol>
            </li>

            <li>
              <h class="h6">ETL Referral Program</h><br /><br />

              <ol class="ordered-list">
                <li>
                  The Program<br /><br />

                  On certain ETL Sites, ETL may offer members the chance to earn ETL Coupons, when
                  they invite friends to become members of a ETL Site and those friends make a
                  confirmed booking through an authorized ETL invitation channel (“ETL Authorized
                  Channel”) (e.g. an invitation sent through the ETL website, Facebook or another
                  social media channel as supported by ETL).<br /><br />
                </li>

                <li>
                  Terms<br /><br />

                  You may only earn ETL Coupons via ETL’s authorized member invite mechanisms.
                  Referrals outside of ETL Authorized Channel will not result in any ETL Coupons.
                  You understand that ETL Coupons are not transferable, may not be auctioned,
                  bartered or sold, and may not be pooled with other members.<br /><br />

                  You will only receive ETL Coupons for the first confirmed booking made by a friend
                  who makes his or her first booking on the ETL Sites and subsequently completes his
                  or her first booking as a result of an invite from you via an ETL Authorized
                  Channel. If your friend does not follow the directions in the invite email or
                  other valid invite mechanism to accept the invitation, you may not receive ETL
                  Coupons, and ETL will have no liability to you for your friend’s failure to follow
                  directions.<br /><br />

                  You agree that having multiple ETL Accounts is a violation of this Terms of Use
                  and that sending invites to alternate email addresses or accounts or otherwise
                  attempting to circumvent the ETL Referral Program system may, without limiting any
                  of ETL’s other rights or remedies, result in forfeiture of your membership and all
                  ETL Coupons in your ETL Account.<br /><br />

                  You must not conduct your own promotion in connection with the ETL Referral
                  Program. You may not engage in any promotional, marketing, or other advertising
                  activities on behalf of ETL, including the use of any trademarks of ETL.<br /><br />

                  ETL reserves the right to void referrals and ETL Coupons earned if it is suspected
                  that the referrals or ETL Coupons were earned in a fraudulent manner, in a manner
                  that violates this Terms of Use or in a manner otherwise not intended by ETL.<br /><br />

                  ETL Coupons will be emailed to your designated email address within 24 hours of
                  one of your qualifying invitees completing his or her confirmed booking on the ETL
                  website.<br /><br />

                  ETL reserves the right to modify the values and rewards of the ETL Referral
                  Program based on each user's activity.<br /><br />

                  ETL Referral Program is void where prohibited by law. ETL reserves the right to
                  modify or terminate the ETL Referral Program at any time. Should there be any tax
                  liability for the accumulation and/or use of ETL Coupons, such taxes are the sole
                  responsibility of the participant. You understand that your ETL Account may not
                  accurately reflect the ETL Coupons you have actually earned. ETL will have no
                  liability for any errors displayed in your ETL Account.<br /><br />
                </li>
              </ol>
            </li>

            <li>
              <h class="h6">Privacy Policy</h><br /><br />

              <ol class="ordered-list">
                <li>
                  Privacy Policy<br /><br />

                  For ETL’s policy relating to its use of your personal data, please review ETL’s
                  current Privacy & Cookies Policy, which is hereby incorporated by reference to
                  this Terms of Use. Your acceptance of this Terms of Use constitutes your
                  acceptance and agreement to be bound by our Privacy Policy.<br /><br />
                </li>
              </ol>
            </li>

            <li>
              <h class="h6">Indemnity</h><br /><br />

              <ol class="ordered-list">
                <li>
                  ETL’s Indemnification<br /><br />

                  You will indemnify and hold ETL, our holding companies, subsidiaries, affiliates,
                  officers, directors and employees harmless from, including but not limited to all
                  damages, liabilities, settlements, costs and attorney’s fees, claims or demands
                  made by any third party due to or arising out of your access to the ETL website,
                  use of the ETL website, your violation of this Terms of Use, or the infringement
                  of any intellectual property or other right of any person or entity by you or any
                  third party using your ETL User ID.<br /><br />
                </li>

                <li>
                  ETL’s Involvementtion<br /><br />

                  ETL may, if necessary, participate in the defense of any claim or action and any
                  negotiations for settlement. You will not make any settlement that may adversely
                  affect the rights or obligations of ETL without ETL’s prior written approval. ETL
                  reserves the right, at its expense and upon notice to you to assume exclusive
                  defense and control of any claim or action.<br /><br />
                </li>
              </ol>
            </li>

            <li>
              <h class="h6">Disclaimers and Limitation of Liability</h><br /><br />

              <ol class="ordered-list">
                <li>
                  Parties’ Relationship<br /><br />

                  ETL has no special relationship with or fiduciary duty to you for accessing and
                  using the ETL website and the Content. You acknowledge that ETL has no control
                  over, and no duty to take any action regarding: (a) Which users gain access to
                  this ETL website; (b) What content you access via the ETL website; (c) What
                  effects the Content may have on you; (d) How you may interpret or use the Content;
                  and (e) What actions you may take as a result of having been exposed to the
                  Content.<br /><br />
                </li>

                <li>
                  Services and Comments<br /><br />

                  You acknowledge and agree that ETL Operators may offer Services on the ETL
                  website, and that suggestions or recommendations may be given by the ETL Operators
                  or Users of the ETL website. ETL makes no representations or warranties regarding
                  the accuracy of descriptions anywhere on the Services, or regarding suggestions or
                  recommendations of the Services offered or purchased through the ETL website.<br /><br />
                </li>

                <li>
                  Exemption of Liability<br /><br />

                  In no event will ETL, the ETL website, or any of ETL’s holding companies,
                  subsidiaries, affiliates, officers, directors and/or employees be liable for any
                  loss of profits or any indirect, consequential, special, incidental, or punitive
                  damages arising out of, based on, or resulting from: (a) This ETL website; or (b)
                  This Terms of Use; or (c) Any breach of this Terms of Use by you or a third party;
                  or (d) Use of the ETL website, tools or Services we provide related to the
                  business we operate on the ETL website by you or any third party; or (e) Any
                  actual or attempted communication or transaction between users, in each case, even
                  if we have been advised of the possibility of such damages.<br /><br />

                  These limitations and exclusions apply without regard to whether the damages arise
                  from: (a) Breach of contract; or (b) Breach of warranty; or (c) Strict liability;
                  or (d) Tort; or (e) Negligence; or (f) Any other cause of action, to the maximum
                  extent that such exclusion and limitations are not prohibited by applicable
                  law.<br /><br />

                  This ETL website, including all content, software, functions, materials and
                  information made available on or accessed through the ETL website, is provided on
                  an "as is" basis. To the fullest extent permissible by applicable law, ETL makes
                  no representations or warranties of any kind, either express or implied, including
                  but not limited to the content on the ETL website or the materials, information
                  and functions made accessible through the ETL website, for any of the Services or
                  hypertext links to third parties or for any breach of security associated with the
                  transmission of sensitive information through the ETL website, or for ETL Operator
                  ability, fitness for a particular purpose, non-infringement, or that the use of
                  the Services will be uninterrupted or error-free.<br /><br />

                  You acknowledge and agree that any transmission to and from the ETL website is not
                  confidential and your communications may be read or intercepted by others. You
                  further acknowledge and agree that by submitting communications to ETL and by
                  posting information on the ETL website, including tours and/or activities, no
                  confidential, fiduciary, contractually implied or other relationship is created
                  between you and ETL other than pursuant to this Terms of Use.<br /><br />

                  You acknowledge and agree that you will not hold or seek to hold ETL responsible
                  for the content provided by any ETL Operator or third party and you further
                  acknowledge and agree that ETL is not a party to any transaction in relation to
                  the Services provided by any ETL Operator or third party. ETL has no control over
                  and does not guarantee the safety of any transaction, tours and/or activities or
                  the truth or accuracy of any listing or other content provided by any Operator or
                  third party on the ETL website.<br /><br />
                </li>

                <li>
                  Remedies<br /><br />

                  If you are dissatisfied with the ETL website, do not agree with any part of this
                  Terms of Use, or have any other dispute or claim with or against ETL or another
                  user of the ETL website with respect to this Terms of Use or this ETL website,
                  your sole and exclusive remedy against ETL is to discontinue use of the ETL
                  website.<br /><br />

                  In any event, to the fullest extent permissible by the applicable law, ETL’s
                  liability, and the liability of any member of ETL, to you or any third party in
                  any circumstance arising out of or in connection with the ETL website is limited
                  to the greater of: (a) The amount of fees you paid to ETL in the twelve months
                  prior to the action giving rise to liability; or (b) Sri Lankan Rupees 10,000 in
                  the aggregate for all claims.<br /><br />
                </li>
              </ol>
            </li>

            <li>
              <h class="h6">Interaction with Third Parties</h><br /><br />

              <ol class="ordered-list">
                <li>
                  Links to Third Party Websites<br /><br />

                  This ETL website may contain links to third party websites or services (“Third
                  Party Websites”) that are not owned or controlled by ETL, or the Services may be
                  accessible by logging in through a Third Party Website. Links to Third Party
                  Websites do not constitute an endorsement or recommendation by ETL of such Third
                  Party Websites or the information, products, advertising or other materials
                  available on those Third Party Websites.<br /><br />

                  When you access Third Party Websites, you do so at your own risk. You hereby
                  represent and warrant that you have read and agree to be bound by all applicable
                  policies of any Third Party Websites relating to your use of the Services and that
                  you will act in accordance with those policies, in addition to your obligations
                  under this Terms of Use.<br /><br />

                  ETL has no control over, and assumes no responsibility for, the content, accuracy,
                  privacy policies, or practices of or opinions expressed in any Third Party
                  Websites. In addition, ETL will not and cannot monitor, verify, censor or edit the
                  content of any Third Party Website. By using the Services, you expressly relieve
                  and hold ETL harmless from any and all liability arising from your use of any
                  Third Party Website.<br /><br />

                  Your interactions with organizations and/or individuals found on or through the
                  Services including but not limited to the Operators, including payment and
                  delivery of goods or services, and any other terms, conditions, warranties or
                  representations associated with such dealings, are solely between you and such
                  organizations and/or individuals.<br /><br />

                  You should conduct whatever investigation you feel necessary or appropriate before
                  proceeding with any online or offline transaction with any of these third
                  parties.<br /><br />
                </li>

                <li>
                  Services and Comments<br /><br />

                  You acknowledge and agree that ETL Operators may offer Services on the ETL
                  website, and that suggestions or recommendations may be given by the ETL Operators
                  or Users of the ETL website. ETL makes no representations or warranties regarding
                  the accuracy of descriptions anywhere on the Services, or regarding suggestions or
                  recommendations of the Services offered or purchased through the ETL website.<br /><br />
                </li>

                <li>
                  ETL’s Responsibility<br /><br />

                  You agree that ETL shall not be responsible or liable for any loss or damage of
                  any sort incurred as the result of any such dealings. If there is a dispute
                  between participants on this site, or between users and any third party, you
                  understand and agree that ETL is under no obligation to become involved. In the
                  event that you have a dispute with one or more other users or third parties, you
                  hereby release ETL, its holding companies, subsidiaries, officers, directors,
                  employees, agents, and successors in rights from claims, demands, and damages
                  (actual and consequential) of every kind or nature, known or unknown, suspected or
                  unsuspected, disclosed or undisclosed, arising out of or in any way related to
                  such disputes.<br /><br />
                </li>
              </ol>
            </li>

            <li>
              <h class="h6">Termination</h><br /><br />

              <ol class="ordered-list">
                <li>
                  Termination by ETL<br /><br />

                  This Terms of Use shall remain in full force and effect while you use ETL websites
                  or the Services. ETL may terminate or suspend your access to the Services or your
                  membership at any time, for any reason, and without notice, which may result in
                  the forfeiture and destruction of all information associated with your membership.
                  ETL may also terminate or suspend any and all Services and access to the ETL
                  website immediately, without prior notice or liability, if you breach any of the
                  terms or conditions of this Terms of Use.<br /><br />
                </li>

                <li>
                  Effects of Termination<br /><br />

                  Upon termination of your ETL Account, your right to use the Services, access the
                  ETL website, and any Content will immediately cease. All provisions of this Terms
                  of Use which, by their nature, should survive termination, shall survive
                  termination, including but not limited to ownership provisions, warranty
                  disclaimers, and limitations of liability.<br /><br />

                  If this Terms of Use is terminated as a result of your breach, ETL reserves the
                  right to cancel any outstanding Vouchers you may have purchased prior to said
                  termination, without refund or liability to you.<br /><br />
                </li>
              </ol>
            </li>

            <li>
              <h class="h6">Passports, Visas, Documents, Licenses & Insurances</h><br /><br />

              <ol class="ordered-list">
                <li>
                  Your Responsibilities<br /><br />

                  It is the responsibility of all passengers, regardless of nationality and
                  destination, to check with the consulate of the country they are visiting for
                  current entry requirements.<br /><br />

                  As visa and health requirements are subject to changes without notice, ETL
                  recommends that you verify health and visa requirements with the appropriate
                  consulate prior to departure.<br /><br />

                  You acknowledge that some Operators may require you to adhere to local policies
                  and furnish the necessary documents and/or licenses as a prerequisite to using
                  and/or participating in the Services they offer.<br /><br />

                  ETL strongly recommends that you purchase a comprehensive travel insurance policy
                  prior to departure.<br /><br />
                </li>
              </ol>
            </li>

            <li>
              <h class="h6">Governing Law</h><br /><br />

              This Terms of Use shall be governed by the laws of the country in which you reserve
              and/or book your respective experience or activity. You agree to submit to the
              non-exclusive jurisdiction of the relevant country.<br /><br />
            </li>

            <li>
              <h class="h6">Miscellaneous</h><br /><br />

              <ol class="ordered-list">
                <li>
                  Severability<br /><br />

                  If any provision of this Terms of Use is found to be unenforceable or invalid,
                  that provision shall be limited or eliminated to the minimum extent necessary so
                  that this Terms of Use shall otherwise remain in full force and effect and
                  enforceable.<br /><br />
                </li>

                <li>
                  Assignment<br /><br />

                  This Terms of Use is not assignable, transferable or sub-licensable by you except
                  with ETL’s prior written consent. ETL may transfer, assign or delegate this Terms
                  of Use and its rights and obligations without prior notice to you.<br /><br />

                  The Terms of Use have been drafted, and shall be construed, in the English
                  language. Any translation of the Terms of Use is solely for reference only. In the
                  event of inconsistency between the English language version and a translated
                  version, the English language version of the Terms of Use shall always prevail.<br /><br />
                </li>
              </ol>
            </li>

            <li>
              <h class="h6">Contact</h><br /><br />

              Please contact ETL at info@explorethelocal.com to report any violations of this Terms
              of Use or to pose any questions regarding this Terms of Use or the Service.<br /><br />

              <i>Last updated on 28 March 2021</i>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </CommonLayout>
</template>

<style scoped lang="scss">
//importing stylesheet for the booking policy page
@import "/src/styles/views/_terms-conditions.scss";
</style>
